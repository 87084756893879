<template>
  <div>
    <!-- ======================Mobile===================== -->
    <div class="row mobile" style="background: #f4f4f4">
      <NavBarSeloCheckout />

      <div class="mt-3" :style="carregarLoading ? 'position:fixed;' : ''">
        <!-- Seus Dados  -->
        <div class="row mt-3">
          <div class="col-2 box-line">
            <div class="passo-circle-completo" :class="{ filled: step >= 2 }">
              {{ step > 1 ? "✓" : "1" }}
            </div>
            <hr class="line" />
          </div>
          <div class="col-10 my-2">
            <div class="texto-superio">Seus dados</div>
          </div>
        </div>

        <!-- Confirmação -->
        <div class="row">
          <div class="col-2 box-line">
            <div :class="
              step >= 2 ? 'passo-circle-completo ' : 'passo-circle filled'
            ">
              {{ step > 2 ? "✓" : "2" }}
            </div>
            <hr :class="step == 1 ? 'line3' : 'line'" />
          </div>

          <div class="col-10 my-2 ">
            <div class="texto-superio">Confirmação</div>
          </div>
        </div>

        <!-- Pagamento -->
        <div class="row">
          <div class="col-2 box-line">
            <div :class="step >= 3 ? 'passo-circle-completo ' : 'passo-circle filled'">
              {{ step > 3 ? "✓" : "3" }}
            </div>
            <hr class="line" />
          </div>

          <div class="col-10 my-2">
            <div class="texto-superio">Pagamentos</div>
          </div>
        </div>

        <!-- Sucesso -->
        <div class="row">
          <div class="col-2 box-line">
            <div :class="
              step >= 3 ? 'passo-circle-completo ' : 'passo-circle filled'">
              {{ step >= 4 ? "✓" : "4" }}
            </div>
            <hr class="line4" />
          </div>

          <div class="col-10 mt-2">
            <div class="texto-superio">Sucesso</div>

          </div>
        </div>
        <div class="text-center" v-show="step == 4 ? true : false">
          <div class=" mt-5">
            <div>
              <img src="/img/Sucesso.svg" />
            </div>

            <div class=" mt-5"
              style="font-family: 'Roboto';font-style: normal;font-weight: 500;font-size: 18px;color: #222222;">
              Obrigado pela sua compra!
            </div>
            <div>
              <div class=" mt-4 pb-4"
                style="font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 16px;color: #6F6F6F;">
                Sua compra foi realizada com sucesso! Você vai receber um
                e-mail explicando os próximos passos e em breve estará
                recebendo seu selo.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ======================DeskTop===================== -->
    <div class="desktop">
      <NavBarSeloCheckout />
      <section class="layout-desktop">
        <div>
          <div class="stepper-wrapper" style="padding-top: 39px;">
            <div class="stepper-item completed">
              <div class="step-counter text-white">
                {{ step > 1 ? "✓" : "1" }}
              </div>
              <div class="step-name">
                <div class="textor-superio-desktop">Seus dados</div>
              </div>
            </div>
            <div class="stepper-item" :class="step >= 2 ? 'completed' : 'active'">
              <div class="step-counter text-white">
                {{ step > 2 ? "✓" : "2" }}
              </div>
              <div class="step-name">
                <div class="textor-superio-desktop">Confirmação</div>
              </div>
            </div>
            <div class="stepper-item" :class="step >= 3 ? 'completed' : 'active'">
              <div class="step-counter text-white"> {{ step >= 3 ? "✓" : "3" }}</div>
              <div class="step-name">
                <div class="textor-superio-desktop">Pagamento</div>
              </div>
            </div>
            <div class="stepper-item" :class="step >= 3 ? 'completed' : 'active'">
              <div class="step-counter text-white"> {{ step >= 3 ? "✓" : "4" }}</div>
              <div class="step-name">
                <div class="textor-superio-desktop">Sucesso</div>
              </div>
            </div>
          </div>

          <div style="font-family: 'Roboto';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 20px;
                                color: #222222;padding-top: 40px;margin-left: 100px;">
            <div v-if="step == 1">
              Confirme seus dados para proceguir com o pedido.
            </div>
            <div v-else-if="step == 2">
              Confirme a quantidade de selos do seu pedido.
            </div>
            <div v-else-if="step == 3">Escolha e método de pagamento.</div>
          </div>

          <!-- Pagina de Sucesso -->
          <div v-show="step == 4 ? true : false">
            <div class="col-12 mt-5" style="margin-bottom: 68px;">
              <div class="row">
                <div class="col-12" style="text-align: center;">
                  <img src="/img/Sucesso.svg" />
                </div>
              </div>
              <div class="row">
                <div class="col-12" style="
                      font-family: 'Archivo';
                                      font-style: normal;
                                      font-weight: 500;
                                      font-size: 48px;
                                      text-align: center;
                                      color: #222222;">
                  Obrigado pela sua compra!
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-5"
                  style="font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 20px;text-align: center;color: #222222;">
                  Sua compra foi realizada com sucesso! Você vai receber um
                  e-mail explicando os próximos passos e em breve estará
                  recebendo seu selo.
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
    <RodapeSelo :smoothScroll="false" :menuHeight="menuHeight"> </RodapeSelo>
  </div>
</template>
<script>
import auth from "../../auth";
import config from "../../config";
import "vue-select/dist/vue-select.css";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import helper from "../../helpers/helper";
import validacaoCNPJ from "../../helpers/validacaoCNPJ";
import RodapeSelo from "../../components/RodapeSelo";
import axios from "axios";
import Swal from "sweetalert2";
import captureError from "../../helpers/captureError";
import Card from "card";
import NavBarSeloCheckout from '../../components/NavBarSeloCheckout'

export default {
  components: {
    RodapeSelo,
    NavBarSeloCheckout,
  },
  data() {
    return {
      helper,
      carregarLoading: false,
      auth,
      step: 4, menuHeight: 0,

      //step 1
      //Dados pessoais
      nome: undefined,
      email: undefined,
      celular: undefined,
      cpf: undefined,
      //Endereço de entrega
      uf: undefined,
      bairro: undefined,
      cidade: undefined,
      complemento: undefined,
      cep: undefined,
      numeroEndereco: undefined,
      endereco: undefined,
      loadingCep: false,

      //step 2
      qtdSelo: 1,
      valorTotalSelo: undefined,

      //step 3
      loadingFinalizar: false,
      codigoSeguranca: undefined,
      validade: undefined,
      numeroCartao: undefined,
      nomeImpresso: undefined,
      config,
      formatacaoMoeda,
      validacaoCNPJ,
      urlQRcode: undefined,
      textoQRcode: undefined,
      idFaturaIugu: undefined,
      showDadosCartao: true,
      exibirQrCode: false,
      valorTotal: undefined,
    };
  },
  created() {
    this.helper.removeLocalStorage("__CARRINHO_SELO");
    this.helper.removeLocalStorage("__DADOS_PEDIDO_PIX");
    this.helper.removeLocalStorage("__DADOS_PESSOAIS_SELO");
  },
  mounted() {
    window.scrollTo(0, 0);
    initCardPlugin(this.$refs.formElm);
    //Configurando pagamento via Iugu
    if (window["Iugu"]) {
      window["Iugu"].setAccountID(config.IUGU_ACCOUNT);
      window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
    }

    this.verificarScriptIugu();
  },
  methods: {
    copiarPix() {
      var copyText = document.getElementById("textoQRcode");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        title: "O Código foi copiado!",
        text:
          `${copyText.value}`,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    },
    async verificarPagamentoPix(idFaturaIugu) {
      while (this.step === 3) {
        try {
          var resposta = await axios.get(
            `${config.API_URLV2}/PagamentoSelo/` + idFaturaIugu
          );
          if (resposta.data.status === "paid") {
            this.$router.replace('/selo/checkout-sucesso')
          }
        } catch (error) {
          this.loadingFinalizar = false;
        }
      }
    },
    voltarPagamento() {
      this.$router.replace('/selo/checkout-pagamento')
    },
    verificarScriptIugu() {
      setTimeout(() => {
        var naoCarregouScriptsIugu =
          window["Iugu"] == undefined;

        // var naoCarregouScriptsIugu =
        //   window["Iugu"] == undefined ||
        //   window["ka"] == undefined ||
        //   window["ka"]["sessionId"] == undefined;

        if (naoCarregouScriptsIugu) {
          Swal.fire({
            icon: "error",
            title: "Atenção!",
            text:
              "Parece que seu navegador possui alguma extensão que está bloqueando o pagamento. Tente desabilitar as extensões ou tente acessar de outro navegador.",
          }).then(() => {
            window.location.reload();
          });
        }
      }, 1618 * 2);
    },
    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        try {
          var cc = window["Iugu"].CreditCard(
            dados.number,
            dados.expirationMonth,
            dados.expirationYear,
            dados.firstName,
            dados.lastName,
            dados.cvc
          );

          var valido = cc.valid();

          if (valido == false) {
            reject("Cartão inválido. Por favor verifique os dados.");
          }

          window["Iugu"].createPaymentToken(cc, function (data) {
            if (data.errors) {
              reject(
                "Ocorreu um erro ao tentar processar o pagamento com seu cartão. Favor verificar os dados e confirmar se seu cartão está liberado para compras online."
              );
            } else {
              resolve(data.id);
            }
          });
        } catch (e) {
          reject("Erro ao criar token Iugu: " + JSON.stringify(e));
        }
      });
    },
    obterValidadeCartao() {
      var mesCartao = 0;
      var anoCartao = 0;

      if (this.validade) {
        var partes = this.validade.split("/");
        mesCartao = partes[0];
        anoCartao = partes[1];
      }

      return [mesCartao, anoCartao];
    },
    async gerarHashCartao() {
      var validadeCartaoArr = this.obterValidadeCartao();
      var hash = await this.gerarHashIuguAsync({
        number: this.numeroCartao.replace(/ /g, ""),
        expirationMonth: validadeCartaoArr[0],
        expirationYear: validadeCartaoArr[1],
        firstName: helper.quebrarNome(this.nomeImpresso)[0],
        lastName: helper.quebrarNome(this.nomeImpresso)[1],
        cvc: this.codigoSeguranca,
      });
      return hash;
    },
    async RealizarPagamento() {
      this.loadingFinalizar = true;
      try {
        var qtdSelo = this.helper.getLocalStorage("__CARRINHO_SELO");
        var dadosSelo = this.helper.getLocalStorage("__DADOS_PESSOAIS_SELO");
        var parseDados = JSON.parse(dadosSelo);
        var data = {
          token: "",
          quantidade: qtdSelo,
          nome: parseDados.nome,
          cidade: parseDados.cidade,
          email: parseDados.email,
          numero: parseDados.numeroEndereco,
          logradouro: parseDados.endereco,
          complemento: parseDados.complemento,
          cep: parseDados.cep,
          bairro: parseDados.bairro,
          telefone: parseDados.celular,
          cpfCnpj: parseDados.cpf,
          formaPagamento:
            this.showDadosCartao === true ? "cartaoCredito" : "pix",
          uf: parseDados.uf,
        };

        if (this.showDadosCartao === true) {
          data.token = await this.gerarHashCartao();
        }

        var resposta = await axios.post(
          `${config.API_URLV2}/PagamentoSelo`,
          data
        );

        if (resposta.data.sucesso === true) {
          if (this.showDadosCartao === true) {
            this.$router.replace('/selo/checkout-sucesso')
            this.loadingFinalizar = false;
            return;
          } else {
            this.urlQRcode = resposta.data.urlQRCode;
            this.textoQRcode = resposta.data.qRcodeTexto;
            this.idFaturaIugu = resposta.data.idFaturaIugu;
            this.exibirQrCode = true;
            this.showDadosCartao = false;
            this.valorTotal = resposta.data.valorTotal;
            await this.verificarPagamentoPix(this.idFaturaIugu);
          }
        } else {
          this.$router.replace('/selo/checkout-erro')
        }
        this.loadingFinalizar = false;
      } catch (error) {
        this.loadingFinalizar = false;
        this.$router.replace('/selo/checkout-erro')
      }
    },
    mensagemErrorFinalizarSeguro(error) {
      var errorMessage = error?.response?.data?.message;
      if (error?.response?.data?.error) {
        this.$toast.error(error?.response?.data?.error, {
          duration: 8000,
          position: "top",
        });
      } else if (error?.response?.data?.erroCartao) {
        Swal.fire({
          title: "Atenção",
          text:
            "Não foi possível realizar a validação do seu cartão. Por favor verifique o saldo disponível ou tente utilizar outro cartão.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else if (
        errorMessage ||
        (error &&
          error
            .toString()
            .toLowerCase()
            .indexOf("cartão") !== -1)
      ) {
        Swal.fire({
          title: "Atenção",
          text: errorMessage || error.toString(),
          icon: "warning",
          confirmButtonText: "Ok",
        });
      } else {
        captureError(error, this.email, this.cpf, this.nome);
        Swal.fire({
          title: "Não foi possível finalizar seu pedido",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    gravarCarrinho() {
      this.helper.setLocalStorage("__CARRINHO_SELO", this.qtdSelo);
      this.$router.replace('/selo/checkout-pagamento')
    },
    adicionarQtdSelo() {
      this.qtdSelo++;
      this.valorTotalSelo = (config.VALOR_SELO * this.qtdSelo + config.VALOR_FRETE)
        .toFixed(2)
        .replace(".", ",");
    },
    removerQtdSelo() {
      if (this.qtdSelo > 1) {
        this.qtdSelo--;
        this.valorTotalSelo = (config.VALOR_SELO * this.qtdSelo + config.VALOR_FRETE)
          .toFixed(2)
          .replace(".", ",");
      }
    },
    voltarPasso() {
      if (this.step === 2) {
        this.$router.replace('/selo/checkout-dados')
      } else if (this.step === 3) {
        this.$router.replace('/selo/checkout-confirmacao')
      } else if (this.step === 4) {
        this.$router.replace('/selo/checkout-pagamento')
      } else if (this.step === 5) {
        this.$router.replace('/selo/checkout-pagamento')
      }
    },
    preencherDadosTeste() {
      this.cep = "71694031";
      this.nome = "charles marques";
      this.email = "charles@g.com";
      this.celular = "61982563698";
      this.cpf = "03936563144";
    },
    buscarEnderecoCep(cep) {
      //Busca apenas se tiver digitado o cep completo
      if (!cep || cep.length < 9) {
        return;
      }

      this.loadingCep = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cep}`)
        .then((response) => {
          this.loadingCep = false;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }

          this.endereco = response.data.logradouro;
          this.cidade = response.data.localidade;
          this.bairro = response.data.bairro;
          this.uf = response.data.uf;
          this.cep = this.cep;
          this.numeroEndereco = this.numEndereco;
        })
        .catch((response) => {
          this.loadingCep = false;
          captureError(response);
        });
    },
    formaPagamentoSelecionar(value) {
      if (value === "cartaoCredito") {
        this.showDadosCartao = true;
      } else {
        this.showDadosCartao = false;
      }
    },

    async proximoPasso() {
      if (this.step == 1) {
        if (!this.uf) {
          -Swal.fire("Atenção!", "O campo estado é obrigatório", "warning");
          return;
        }
        const dadosSelo = JSON.stringify({
          nome: this.nome,
          email: this.email,
          celular: this.celular,
          cpf: this.cpf,
          uf: this.uf,
          bairro: this.bairro,
          cidade: this.cidade,
          complemento: this.complemento,
          cep: this.cep,
          numeroEndereco: this.numeroEndereco,
          endereco: this.endereco,
        });

        this.helper.setLocalStorage("__DADOS_PESSOAIS_SELO", dadosSelo);
      }
      this.$router.replace('/selo/checkout-confirmacao')
      return;
    },
  },
  watch: {
    cep: function (value) {
      clearTimeout(window["debounceCep"]);
      window["debounceCep"] = setTimeout(() => {
        this.buscarEnderecoCep(value);
      }, 700);
    },
  },
};

function initCardPlugin(formElm) {
  new Card({
    // a selector or DOM element for the form where users will
    // be entering their information
    form: formElm, // *required*
    // a selector or DOM element for the container
    // where you want the card to appear
    container: ".card-wrapper", // *required*

    formSelectors: {
      numberInput: 'input[name="cardNumber"]', // optional — default input[name="number"]
      expiryInput: 'input[name="cardExpiry"]', // optional — default input[name="expiry"]
      cvcInput: 'input[name="cardCvc"]', // optional — default input[name="cvc"]
      nameInput: 'input[name="cardName"]', // optional - defaults input[name="name"]
    },

    //width: 200, // optional — default 350px
    formatting: true, // optional - default true

    // Strings for translation - optional
    messages: {
      validDate: "valid\ndate", // optional - default 'valid\nthru'
      monthYear: "mm/yy", // optional - default 'month/year'
    },

    // Default placeholders for rendered fields - optional
    placeholders: {
      name: "SEU NOME",
    },

    // if true, will log helpful messages for setting up Card
    debug: false, // optional - default false
  });
}
</script>
<style scoped>
.estilo-pedido-mobile {
  font-family: 'Roboto';
  font-weight: 700;
  text-align: right;
  font-size: 17px;
  color: #222222;
}

.bottom-final {
  box-sizing: border-box;
  width: 47px;
  height: 45.32px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(186, 186, 186);
}

.titulo-btn-primeiro {
  font-family: "Roboto";
  font-weight: 400;
  color: #ffffff;
}

.estilo-preco-pedido {
  font-weight: 700;
  font-size: 20px;
  text-align: right;
}

.estilo-preco-pedido {
  font-family: "Roboto";
  font-weight: 700;
  text-align: right;
  font-size: 20px;
  color: #222222;
}

.estilo-pedido {
  font-weight: 400;
  font-size: 20px;
  color: #a3a3a3;
}

.estilo-pedido {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 20px;
  text-align: left;
  color: #a3a3a3;
}

.esconde-linha {
  display: block;
}

.esconde-linha {
  display: none;
}

.mostra-botao {
  display: none;
}

.oitava-section-retangulo-subtitulo-1 {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0px;
  padding-left: 0px;
  text-align: center;
  padding-bottom: 40px;
}

.oitava-section-retangulo-subtitulo {
  font-family: Roboto;

  font-weight: 400;
  font-size: 16px;
  color: rgb(111, 111, 111);
  margin-bottom: 21px;
}

.oitava-section-retangulo-titulo {
  font-family: "Roboto";

  font-weight: 500;
  font-size: 32px;
  color: #222222;
}

.oitava-section-card {
  background: rgb(255, 255, 255);
  border-radius: 4px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: calc(1.5em + 1rem + 8px);
}

.vs--searchable .vs__dropdown-toggle {
  height: calc(1.5em + 1rem + 8px);
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #23ad21;
  background-color: #23ad21;
}
</style>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500,600|Archivo:400,700,900,300,500,600");

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-planos-periodo {
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}

.toggle-planos {
  background-color: #e7e7e7;
  border-radius: 100px;
  padding: 3px;
  display: inline-flex;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}

.form-control {
  height: calc(1.5em + 1rem + 5px);
  font-size: 1.1rem;
}

.miguelSuperior {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 7px;
  width: 225px;
  right: 05px;
  color: white;
  background: #28a745;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0px 13px 13px 13px;
  font-size: 15px;
}

.line {
  background: #28a745;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.line2 {
  background: #ffffff;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.line4 {
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.line3 {
  background: #c4c4c4;
  height: 100%;
  width: 2px;
  padding: 0px;
  margin: 0 auto;
}

.box-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-line-miguel {
  display: flex;
  flex-direction: column;
}

.passo-circle-completo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  background-color: #23ad21;

  border: 3px solid #23ad21;
  color: white;
  text-align: center;
}

.passo-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #c4c4c4;
  color: #23ad21;
  text-align: center;
}

.passo-circle.filled {
  background-color: #c4c4c4;
  color: white;
}

.texto-superio {
  color: #11481d;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.badge-success {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-top: -7px;
  float: right;
  margin-right: 25px;
  border-radius: 5px;
}

.font-size-infor {
  font-size: 12px;
  font-weight: 500;
}

.bloquei-pagina {
  position: fixed;
}

.btn-block {
  padding: 12px;
  border-radius: 10px;
}

.btn-planos {
  padding: 10px;
  border-start-end-radius: 7px;
  border-start-start-radius: 7px;
  display: block;
  width: 100%;
  margin-bottom: -1px;
}

.plano-detalhes {
  margin-left: -22px;
}

/*================== DeskTop =================================*/
.oitava-section-retangulo {
  text-align: center;
  width: 40%;
  height: 60%;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 3px 3px 50px;
}

.oitava-section-retangulo-logo {
  width: 70%;
  padding-top: 8px;
}

.oitava-section-retangulo-qrcode {
  width: 70%;
  padding-bottom: 8px;
}

.layout-desktop {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 30px;
  padding-bottom: 100px;
  background-color: #f4f4f4;
}

.retangulo-desktop {
  background: linear-gradient(180deg, #303030 0%, rgba(0, 0, 0, 0) 100%),
    #28a745;
}

.textor-superio-desktop {
  color: #11481d;
  font-weight: 500;
  line-height: 0.2;
  font-size: 12px;
  text-align: center;
}

.row {
  margin: 0;
}

.badge-success-desktop {
  color: #fff;
  text-align: left;
  background-color: #28a745;
  margin-top: -7px;
  float: right;
  margin-right: 25px;
  border-radius: 5px;
}

.font-size-infor-desktop {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  color: #222222;
  padding-left: 19px;
}

.font-size-infor-desktop-planos {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
  margin-left: 15px;
  color: #11481d;
}

.idenizacao {
  font-size: 13px;
}

.btn-block-desktop {
  border-radius: 5px;
  display: block;
  width: 100%;
  margin-bottom: -1px;
}

.img-miguel-desktop {
  width: 100px;
}

.miguel-desktop {
  text-align: center;
  position: relative;
  margin-top: 98px;
}

.bem-vindo-desktop {
  padding: 21px;
  bottom: 38.92%;
  margin-top: 28px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}

.bem-vindo-desktop-2 {
  padding: 21px;
  padding-bottom: 1.92%;
  margin-top: 30px;
  background: #ffffff;
  border-radius: 24px 0px 24px 24px;
  font-size: 14px;
}

.texto-desktop {
  font-size: 12px;
  line-height: 20px;
  /* or 120% */
  display: flex;
  align-items: center;

  color: #777777;
}

.sucesso-botao-desktop {
  width: 180px;
  border-radius: 10px;
  padding: 11px;
  margin-bottom: 30px;
}

.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #23ad21;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #23ad21;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
}

.card-body-plano-desktop {
  min-height: 394px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}

.card-body-plano-desktop-prata {
  min-height: 294px;
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: #ffffff;
  border: 1px solid #a3a5a3;
  box-sizing: border-box;
  border-radius: 10px;
}

.plano-recomendado-desktop {
  min-height: 447px;
}

.plano-recomendado-desktop-ouro {
  min-height: 379px;
}

.plano-nao-recomendado-desktop {
  margin-top: 20px;
}

.texto-card-desktop {
  font-size: 13px;
}

.card-footer-desktop {
  padding: 0rem 0em;
  background-color: rgb(255 255 255 / 3%);
  border-top: 1px solid rgb(255 255 255 / 13%);
}

.mobile {
  display: none;
}

.texto-desconto-desktop {
  font-size: 12px;
  color: #28a745;
  line-height: 19px;
  font-weight: 700;
  background-color: #c4ffc3;
  border-radius: 100px;
  padding-left: 3px;
  padding-right: 7px;
}

.icon-desconto {
  vertical-align: text-top;
  width: 16px;
}

.titulo {
  display: inline-flex;
}

@media (max-width: 768px) {
  .mobile {
    display: block;
  }
}

@media (max-width: 768px) {
  .desktop {
    display: none;
  }
}

@media (max-width: 1198px) {
  .texto-card-desktop {
    font-size: 20px;
  }

  .pricing-card-title-desktop {
    font-size: 35px;
  }

  .card-title-desktop {
    font-size: 27px;
  }
}
</style>
  